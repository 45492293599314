import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
//import ContactNav from '../components/contact-nav';

const Page = ({ data }) => {
  const breadPaths = [
    {
      text: "component list",
    },
  ]

  return (
    <Layout breadPaths={breadPaths}>
      <SEO title="Home" />

      {/* 1st block */}

      <section className="flex flex-col-reverse lg:flex-row w-full relative over:-mx-1col1gap over:w-14col13gap">
        <div className="flex flex-col md:flex-row lg:flex-col lg:w-3col2gap over:w-4col3gap lg:flex-grow-0 over:pl-1col1gap">
          <div className="hidden lg:flex md:w-3col2gap flex-shrink-0 flex-row items-center">
            <div className="hidden xl:block relative mb-1gap md:mb-2gap mx-auto w-2/3 max-w-3col xl:w-full">
              <img
                className="block rounded-full border-black border-1gap"
                src="/imgs/yuko-profile.jpg"
                alt=""
              />
              <span
                className="hidden lg:block absolute w-screen border-black lg:border-t-1gap right-0 top-0"
                style={{ marginRight: "50%" }}
              ></span>
              <span
                className="hidden lg:block absolute w-full border-black lg:border-t-1gap left-0 bottom-0"
                style={{ marginLeft: "50%" }}
              ></span>
            </div>
          </div>

          <div className="flex flex-col flex-grow mb-2gap justify-center xl:justify-start">
            <div>
              <h2 className="font-bold text-2xl leading-tight mb-1gap">
                Atelier Y+はドイツ美大受験専門のオンラインクラスです
              </h2>
              <p>
                彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。
              </p>
            </div>
          </div>
        </div>

        <div className="flex-grow lg:pl-1gap xl:pl-1col overflow-hidden relative">
          <div className="lg:pl-1gap pg:pt-1gap pb-1gap lg:pb-2gap relative">
            <div className="bg-black border-black border-1/2gap lg:border-1gap lg:-ml-1gap">
              <img
                className="block w-full shadow-md max-w-5col4gap lg:max-w-full mx-auto"
                src="https://placekitten.com/880/620"
                alt=""
              />
            </div>
            <span className="hidden lg:block absolute w-screen border-black border-t-1gap left-0 top-0"></span>
            <span className="hidden lg:block absolute h-screen border-black border-l-1gap left-0 top-0"></span>
          </div>
        </div>

        <span
          className="hidden lg:block absolute w-screen border-black border-t-1gap top-0"
          style={{ left: "50%" }}
        ></span>
      </section>

      {/* 2nd block */}

      <div className="border-black border-t-1/2gap pt-2gap lg:hidden"></div>

      <section className="flex lg:flex-row-reverse over:-mx-1col1gap over:w-14col13gap">
        <div className="flex-grow-0 lg:w-1/2 xl:w-auto">
          <div className="w-full md:w-4col3gap lg:w-full xl:w-6col5gap over:w-7col6gap">
            <div className="pb-2gap md:mr-1gap lg:mr-0 lg:ml-1col xl:ml-1col1gap over:pr-1col1gap">
              <div className="relative hidden lg:block pt-3gap">
                <span className="absolute left-0 top-0 border-black border-t-1gap w-screen">
                  <span className="hidden lg:block absolute left-0 top-0 border-black border-3/2gap rounded-full -mt-2gap -ml-2gap"></span>
                </span>
              </div>

              <h2 className="font-bold text-3xl leading-tight mb-1gap">
                <Link to="/">
                  ドイツ美大受験
                  <br />
                  オンラインクラス
                </Link>
              </h2>
              <p className="mb-1gap">
                彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろ
              </p>
              <ul className="space-y-2 mt-2 mb-3 ml-1gap leading-tight">
                <li className="list-item-triangle-black">
                  <Link to="/">授業内容</Link>
                </li>
                <li className="list-item-triangle-black">
                  <Link to="/">概要</Link>
                </li>
                <li className="list-item-triangle-black">
                  <Link to="/">講師について</Link>
                </li>
                <li className="list-item-triangle-black">
                  <Link to="/">生徒さんの声</Link>
                </li>
                <li className="list-item-triangle-black">
                  <Link to="/">よくある質問</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="hidden md:block lg:w-1/2 xl:w-auto flex-grow">
          <div className="pb-2gap">
            <img
              className="block w-full border-black border-1/2gap lg:border-1gap shadow-md"
              src="https://placekitten.com/600/400"
              alt=""
            />
          </div>
        </div>
      </section>

      {/* 3rd block */}

      <div className="border-black border-t-1/2gap pt-2gap lg:hidden"></div>

      <section className="flex md:flex-row-reverse lg:flex-row relative over:-mx-1col1gap over:w-14col13gap">
        <div className="flex-grow-0 lg:w-1/2 xl:w-auto">
          <div className="w-full md:w-4col3gap lg:w-full xl:w-6col5gap over:w-7col6gap">
            <div className="pb-2gap md:ml-1gap lg:ml-0 lg:mr-1col xl:mr-1col1gap over:pl-1col1gap">
              <div className="relative hidden lg:block pt-3gap">
                <span className="absolute right-0 top-0 border-black border-t-1gap w-screen">
                  <span className="hidden lg:block absolute right-0 top-0 border-black border-3/2gap rounded-full -mt-2gap -mr-2gap"></span>
                </span>
              </div>

              <h2 className="font-bold text-3xl leading-tight mb-1gap">
                <Link to="/">その他のサービス</Link>
              </h2>
              <p className="mb-1gap">
                彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろ
              </p>
              <ul className="space-y-2 mt-2 mb-3 ml-1gap leading-tight">
                <li className="list-item-triangle-black">
                  <Link to="/">ドイツ語習得サポート</Link>
                </li>
                <li className="list-item-triangle-black">
                  <Link to="/">進路相談サポート</Link>
                </li>
                <li className="list-item-triangle-black">
                  <Link to="/">講師について</Link>
                </li>
                <li className="list-item-triangle-black">
                  <Link to="/">生徒さんの声</Link>
                </li>
                <li className="list-item-triangle-black">
                  <Link to="/">よくある質問</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="hidden md:block lg:w-1/2 xl:w-auto flex-grow">
          <img
            className="block w-full border-black border-1/2gap lg:border-1gap lg:-mb-1gap"
            src="https://placekitten.com/600/600"
            alt=""
          />
        </div>
      </section>

      {/* 4th block */}

      <div className="border-black border-t-1/2gap pt-2gap lg:hidden"></div>

      <section>
        <div className="relative hidden lg:block pt-3gap">
          <span className="absolute left-0 top-0 border-black border-t-1gap w-screen">
            <span className="hidden lg:block absolute left-0 top-0 border-black border-3/2gap rounded-full -mt-2gap -ml-1/2gap xl:-ml-2gap"></span>
          </span>
        </div>

        <h2 className="font-bold text-3xl leading-tight mb-1gap">
          卒業生たちの声
        </h2>
        <p className="mb-2gap">Atelier Y+の卒業生たちの声です。</p>
        <ul className="grid lg:grid-cols-2 gap-1gap">
          <li className="flex items-center">
            <img
              className="block w-1col1gap h-1col1gap md:w-full md:h-auto max-w-2col1gap max-h-2col1gap rounded-full shadow-md"
              src="https://placekitten.com/200/200"
              alt=""
            />
            <p className="ml-1gap">
              彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。
            </p>
          </li>
          <li className="flex items-center">
            <img
              className="block w-1col1gap h-1col1gap md:w-full md:h-auto max-w-2col1gap max-h-2col1gap rounded-full shadow-md"
              src="https://placekitten.com/200/200"
              alt=""
            />
            <p className="ml-1gap">
              彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。
            </p>
          </li>
          <li className="flex items-center">
            <img
              className="block w-1col1gap h-1col1gap md:w-full md:h-auto max-w-2col1gap max-h-2col1gap rounded-full shadow-md"
              src="https://placekitten.com/200/200"
              alt=""
            />
            <p className="ml-1gap">
              彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。
            </p>
          </li>
          <li className="flex items-center">
            <img
              className="block w-1col1gap h-1col1gap md:w-full md:h-auto max-w-2col1gap max-h-2col1gap rounded-full shadow-md"
              src="https://placekitten.com/200/200"
              alt=""
            />
            <p className="ml-1gap">
              彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。
            </p>
          </li>
          <li className="flex items-center">
            <img
              className="block w-1col1gap h-1col1gap md:w-full md:h-auto max-w-2col1gap max-h-2col1gap rounded-full shadow-md"
              src="https://placekitten.com/200/200"
              alt=""
            />
            <p className="ml-1gap">
              彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。
            </p>
          </li>
          <li className="flex items-center">
            <img
              className="block w-1col1gap h-1col1gap md:w-full md:h-auto max-w-2col1gap max-h-2col1gap rounded-full shadow-md"
              src="https://placekitten.com/200/200"
              alt=""
            />
            <p className="ml-1gap">
              彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。
            </p>
          </li>
          <li className="flex items-center">
            <img
              className="block w-1col1gap h-1col1gap md:w-full md:h-auto max-w-2col1gap max-h-2col1gap rounded-full shadow-md"
              src="https://placekitten.com/200/200"
              alt=""
            />
            <p className="ml-1gap">
              彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。
            </p>
          </li>
        </ul>
      </section>

      {/*

      <div className="w-12col11gap">
        The quick brown fox jumps over the lazy dog. 
      </div>

      <p className="text-theme-color-base">hoge</p>
      <p className="text-theme-color-thin">hoge</p>

      <p className="mb-1gap w-1col h-1col bg-black"></p>
      <p className="mb-1gap w-2col h-2col bg-black"></p>
      <p className="mb-1gap w-3col h-3col bg-black"></p>
      <p className="mb-1gap w-4col h-4col bg-black"></p>
      <p className="mb-1gap w-5col h-5col bg-black"></p>
      <p className="mb-1gap w-6col h-6col bg-black"></p>
      <p className="mb-1gap w-7col h-7col bg-black"></p>
      <p className="mb-1gap w-8col h-8col bg-black"></p>
      <p className="mb-1gap w-9col h-9col bg-black"></p>
      <p className="mb-1gap w-10col h-10col bg-black"></p>
      <p className="mb-1gap w-11col h-11col bg-black"></p>
      <p className="mb-1gap w-12col h-12col bg-black"></p>
      <p className="mb-1gap w-13col h-13col bg-black"></p>
      <p className="mb-1gap w-14col h-14col bg-black"></p>


      <div className="mb-12 bg-gray-200 clearfix">
        <div className="flex justify-center lg:float-right">
          <div className="bg-gray-400 p-6" style={{width:"300px", height:"300px"}}>image</div>
        </div>
        <div className="bg-gray-200 flex-auto p-6 overflow-hidden text-4xl lg:mr-10">
          彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い
        </div>
      </div>

      <div className="h-1 bg-gray-400 mb-10"></div>

      <ul className="list-disc pl-10 mb-10 space-y-2">
        { articleNavData.map((nav, i) => 
          (<li key={i}><Link to={nav.href}>{nav.text}</Link></li>)
        )}
      </ul>

      <div className="h-1 bg-gray-400 mb-10"></div>

      <section className="mb-10">
        <h2 className="text-center text-2xl pb-5">ドイツ美大受験への確かな一歩</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          { testimonials.map((item, i) => {
            return (
              <div className="flex flex-col space-y-2" key={i}>
                <div className="self-center rounded-full bg-blue-800 w-24 h-24"></div>
                <p>{item.text}</p>
              </div>
            )
          })}
        </div>
      </section>

      <div className="h-1 bg-gray-400 mb-10"></div>

      <ContactNav></ContactNav>

      <div className="h-1 bg-gray-400 mb-10"></div>

      */}

      <div
        className="mx-auto px-1gap xl:w-12col13gap"
        style={{ border: "1px solid red" }}
      >
        <div className="debug-bar" style={{ background: "orange" }}>
          DEBUG_BAR
        </div>
        <h1 className="text-4xl md:text-5xl leading-tight font-bold py-2gap lg:py-1col">
          ドイツ美大オンラインクラス
        </h1>

        <div className="debug-bar" style={{ background: "orange" }}>
          DEBUG_BAR
        </div>

        <section className="flex -ml-1gap mb-16">
          <div className="flex-grow pl-1gap">
            <h2 className="text-4xl leading-tight pt-5 pb-6 border-black border-t-1gap relative breaking-layout-heading-border-left">
              概要
            </h2>
            <p className="pb-6">
              彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。
              彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。
            </p>
            <p className="pb-6">
              彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。
              彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。
            </p>
            <p className="pb-6">
              彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。
              彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。
            </p>
          </div>
          <div className="flex-grow-0 hidden md:block">
            <div className="pl-1gap md:w-2col2gap lg:w-3col3gap xl:w-4col4gap">
              <img
                className="block w-full"
                src="https://placekitten.com/800/800"
                alt=""
              />
            </div>
          </div>
        </section>

        <div className="debug-bar" style={{ background: "orange" }}>
          DEBUG_BAR
        </div>

        <div className="flex -ml-1gap mb-16 flex-row-reverse">
          <div className="flex-grow pl-1gap">
            <h2 className="text-4xl leading-tight pt-5 pb-6 border-black border-t-1gap relative breaking-layout-heading-border-right">
              概要
            </h2>
            <p className="pb-6">
              彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。
              彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。
            </p>
            <p className="pb-6">
              彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。
              彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。
            </p>
            <p className="pb-6">
              彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。
              彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。
            </p>
          </div>
          <div className="flex-grow-0 hidden md:block">
            <div className="pl-1gap md:w-2col2gap lg:w-3col3gap xl:w-4col4gap">
              <img
                className="block w-full"
                src="https://placekitten.com/700/700"
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="debug-bar" style={{ background: "orange" }}>
          DEBUG_BAR
        </div>

        <div className="flex -ml-1gap mb-16">
          <div className="flex-grow pl-1gap">
            <h2 className="text-4xl leading-tight pt-5 pb-6 border-black border-t-1gap relative breaking-layout-heading-border-left">
              概要
            </h2>
            <div className="yw-RichTextSlices">
              <p>
                彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。
                彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。それとも背後の足音の主は、この街に無数にいる法監視役で、強靭な罰をすぐにも彼の手首にガシャンと下すというのか。
              </p>
              <h2>それとも背後の足音の主は</h2>
              <p>
                彼は足音が止まったことに気が着いた。あわてて辺りを見回す。ふと狭い抜け道に目が止まる。
                彼は素早く右に身を翻し、建物の間に消え去った。その時彼は、もう少しで道の真中に転がっていたごみバケツに躓き転ぶところだった。
                彼は暗闇の中で道を確かめようとじっと見つめた。どうやら自分の通ってきた道以外にこの中庭からの出道はないようだ。
                足音はだんだん近づき、彼には角を曲がる黒い人影が見えた。彼の目は夜の闇の中を必死にさまよい、逃げ道を探す。もうすべては終わりなのか。すべての苦労と準備は水の泡だというのか。
                突然、彼の横で扉が風に揺らぎ、ほんのわずかにきしむのを聞いた時、彼は背中を壁に押し付け、追跡者に見付けられないことを願った。この扉は望みの綱として投げかけられた、彼のジレンマからの出口なのだろうか。背中を壁にぴったり押し付けたまま、ゆっくりと彼は開いている扉の方へと身を動かして行った。この扉は彼の救いとなるのだろうか。
              </p>
              <ol>
                <li>彼は暗闇の中で道を確かめようとじっと見つめた</li>
                <li>
                  べての苦労と準備は水の泡だというのかべての苦労と準備は水の泡だというのかべての苦労と準備は水の泡だというのかべての苦労と準備は水の泡だというのかべての苦労と準備は水の泡だというのかべての苦労と準備は水の泡だというのか
                </li>
                <li>追跡者に見付けられないことを願った</li>
              </ol>
              <p>
                彼は足音が止まったことに気が着いた。あわてて辺りを見回す。ふと狭い抜け道に目が止まる。
                彼は素早く右に身を翻し、建物の間に消え去った。その時彼は、もう少しで道の真中に転がっていたごみバケツに躓き転ぶところだった。
                彼は暗闇の中で道を確かめようとじっと見つめた。どうやら自分の通ってきた道以外にこの中庭からの出道はないようだ。
                足音はだんだん近づき、彼には角を曲がる黒い人影が見えた。彼の目は夜の闇の中を必死にさまよい、逃げ道を探す。もうすべては終わりなのか。すべての苦労と準備は水の泡だというのか。
                突然、彼の横で扉が風に揺らぎ、ほんのわずかにきしむのを聞いた時、彼は背中を壁に押し付け、追跡者に見付けられないことを願った。この扉は望みの綱として投げかけられた、彼のジレンマからの出口なのだろうか。背中を壁にぴったり押し付けたまま、ゆっくりと彼は開いている扉の方へと身を動かして行った。この扉は彼の救いとなるのだろうか。
              </p>
              <ul>
                <li>彼は暗闇の中で道を確かめようとじっと見つめた</li>
                <li>べての苦労と準備は水の泡だというのか</li>
                <li>追跡者に見付けられないことを願った</li>
              </ul>
              <p>
                彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。
                彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。
              </p>
              <h2>強靭な罰をすぐにも</h2>
              <p>
                それとも背後の足音の主は、この街に無数にいる法監視役で、強靭な罰をすぐにも彼の手首にガシャンと下すというのか。彼は足音が止まったことに気が着いた。あわてて辺りを見回す。ふと狭い抜け道に目が止まる。
                彼は素早く右に身を翻し、建物の間に消え去った。
              </p>
              <p></p>
              <p className="block-img">
                <img
                  src="https://images.prismic.io/slicemachine-blank/6b2bf485-aa12-44ef-8f06-dce6b91b9309_dancing.png?auto=compress,format"
                  alt=""
                />
              </p>
            </div>
          </div>
          <div className="flex-grow-0 hidden md:block">
            <div className="pl-1gap md:w-2col2gap lg:w-3col3gap xl:w-4col4gap">
              <img
                className="block w-full"
                src="https://placekitten.com/600/600"
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="debug-bar" style={{ background: "orange" }}>
          DEBUG_BAR
        </div>
      </div>
    </Layout>
  )
}

export default Page
